/* eslint-disable no-unused-vars */
export enum AppRoutes {
    FeaturedCollection = '/homepage/featured-collection',
    CuratedSpaces = '/homepage/curated-spaces',
    CustomizableProducts = '/homepage/customizable-products',
    MoodboardSections = '/homepage/moodboard-sections',
    CollaborationSections = '/homepage/collaboration-sections',
    Products = '/products',
    ProductCategories = '/homepage/product-categories',
    Users = '/admin/users',
    Category = '/categories/admin',
    SubCategory = '/categories/sub-categories/admin',
    JobApplication = '/job-applications',
    Collabs = '/collabs/admin',
    JobPosting = '/job-postings',
    Stories = '/stories/admin',
    Queries = '/contact/admin/queries',
    Catalogue = '/catalogues',
    About = '/about/admin',
    Client = '/clients/admin',
    Spaces = '/spaces/admin',
    Faqs = '/faqs/admin',
    Contact = '/contact/admin',
    HeroImage = '/admin/update-header-images',

    Makes = '/admin/makes',
    City = '/admin/cities',
    Models = '/admin/models',
    CategoryCategories = '/category/categories',
    CategorySubCategories = '/category/sub-categories',
    CouponsPage = '/admin/coupons',
    Faq = '/documitra-content/faqs',
    Service = '/documitra-content/services',
    Advantage = '/documitra-content/advantages',
    HowItWorks = '/documitra-content/how-it-works',
    Reviews = '/documitra-content/reviews',
    PhotomitraAdvantage = '/photomitra/advantages',
    PhotomitraHowItWorks = '/photomitra/how-it-works',
    PhotomitraTakingPhotoStep = '/photomitra/taking-photo-steps',
    PhotoStorePhotSize = '/photo-store/photo-size',
    PhotoStoreFileType = '/photo-store/file-type',
    ContactInfo = '/contact/contact-info',
    ContactForm = '/contact/user-info',
    ApplicationFormServices = '/services',
    ApplicationFormParameters = '/parameters',
    ApplicationFormQuestion = '/questions',
    ApplicationFormPackageServiceAttributes = '/packages/service-attributes',
    ApplicationFormPackageDocuments = '/packages/documents',
    ApplicationFormPackageFeatures = '/packages/features',
    ApplicationFormPackagePlans = '/packages/plans',
    ApplicationFormPackages = '/packages',
    ServiceProcessSteps = '/service-process/process-steps',
    ServiceProcessDetailsProcess = '/service-process/process',
    ServiceProcessSubtitle = '/service-process/process/subtitle',
    AllOrderList = '/admin/orders',
    PickUpManagement = '/admin/third-parties',
    GarageManagement = '/admin/garages',
    GarageManagementApplication = '/admin/garages/application',
    UserList = '/admin/customers',
    Guidelines = '/admin/guidelines',
    PaymentListing = '/admin/payments',
    ThirdPartyCharges = '/admin/third-party-charges',
    TowingCharges = '/admin/towing-vehicle-charges',
    CommissionPage = '/admin/commissions',
    GarageCommissionPage = '/garage/service/garages',
    Dashboard = '/admin/dashboard',
    ServiceList = '/admin/services',
    ReferralCoupons = '/admin/referral-coupons',

    GarageCommission = '/admin/garage-commissions',
    // Remove After Final Work
    StoreStatus = '/store-status',
    Inventory = '/inventory',
    // Categories = '/categories',
    Subcategories = '/subcategories',
    defectTypes = '/Defect-Types',
    complainStatus = '/complaint-status',
    locations = '/locations',
    complaintsPage = '/complaints',
    OrderList = '/orderList',
    managerTechnician = '/manager-technician',
    assets = '/assets',
    locationType = '/location-type',
}
const entityArray = [
    { key: 'item-1', entityName: 'Users', entityUrl: AppRoutes.Users },
    {
        key: 'item-2',
        entityName: 'Home',
        entityUrl: AppRoutes.Makes,
        children: [
            { key: 'Featured Collection', entityName: 'Featured Collection', entityUrl: AppRoutes.FeaturedCollection },
            { key: 'Curated Spaces', entityName: 'Curated Spaces', entityUrl: AppRoutes.CuratedSpaces },
            {
                key: 'Customizable Products',
                entityName: 'Customizable Products',
                entityUrl: AppRoutes.CustomizableProducts,
            },
            { key: 'Moodboard Sections', entityName: 'Moodboard Sections', entityUrl: AppRoutes.MoodboardSections },
            {
                key: 'Collaboration Sections',
                entityName: 'Collaboration Sections',
                entityUrl: AppRoutes.CollaborationSections,
            },
        ],
    },
    { key: 'item-3', entityName: 'Products', entityUrl: AppRoutes.Products },
    {
        key: 'item-4',
        entityName: 'Category Management',
        entityUrl: AppRoutes.Makes,
        children: [
            { key: 'Category', entityName: 'Category', entityUrl: AppRoutes.Category },
            { key: 'Sub Category', entityName: 'Sub Category', entityUrl: AppRoutes.SubCategory },
        ],
    },
    { key: 'item-5', entityName: 'Collabs', entityUrl: AppRoutes.Collabs },
    { key: 'item-6', entityName: 'Job Posting', entityUrl: AppRoutes.JobPosting },
    { key: 'item-7', entityName: 'Job Application', entityUrl: AppRoutes.JobApplication },
    { key: 'item-8', entityName: 'Spaces', entityUrl: AppRoutes.Spaces },
    { key: 'item-9', entityName: 'Stories', entityUrl: AppRoutes.Stories },
    { key: 'item-10', entityName: 'Contact', entityUrl: AppRoutes.Contact },
    { key: 'item-11', entityName: 'Queries', entityUrl: AppRoutes.Queries },
    { key: 'item-12', entityName: 'Catalogue', entityUrl: AppRoutes.Catalogue },
    { key: 'item-13', entityName: 'About', entityUrl: AppRoutes.About },
    { key: 'item-14', entityName: 'Client', entityUrl: AppRoutes.Client },
    { key: 'item-15', entityName: 'Faqs', entityUrl: AppRoutes.Faqs },
    { key: 'item-16', entityName: 'Hero Image', entityUrl: AppRoutes.HeroImage },

    // { key: 'item-2', entityName: 'Pickup Management', entityUrl: AppRoutes.PickUpManagement },
    // { key: 'item-3', entityName: 'Garage Management', entityUrl: AppRoutes.GarageManagement },
    // { key: 'item-4', entityName: 'Orders', entityUrl: AppRoutes.AllOrderList },
    // { key: 'item-5', entityName: 'User List', entityUrl: AppRoutes.UserList },
    // { key: 'item-6', entityName: 'Payments', entityUrl: AppRoutes.PaymentListing },
    // {
    //     key: 'item-7',
    //     entityName: 'Vehicle',
    //     entityUrl: AppRoutes.Makes,
    //     children: [
    //         { key: 'Makes', entityName: 'Makes', entityUrl: AppRoutes.Makes },
    //         { key: 'Models', entityName: 'Models', entityUrl: AppRoutes.Models },
    //     ],
    // },
    // {
    //     key: 'item-8',
    //     entityName: 'Service Category',
    //     entityUrl: AppRoutes.CategoryCategories,
    //     children: [
    //         { key: 'Categories', entityName: 'Categories', entityUrl: AppRoutes.CategoryCategories },
    //         { key: 'SubCategories', entityName: 'SubCategories', entityUrl: AppRoutes.CategorySubCategories },
    //     ],
    // },
    // {
    //     key: 'item-9',
    //     entityName: 'Charges',
    //     entityUrl: AppRoutes.ThirdPartyCharges,
    //     children: [
    //         { key: 'Third-Party-Charges', entityName: 'Third Party Charges', entityUrl: AppRoutes.ThirdPartyCharges },
    //         { key: 'Towing-Charges', entityName: 'Towing Charges', entityUrl: AppRoutes.TowingCharges },
    //         { key: 'Commission-Page', entityName: 'Commission Page', entityUrl: AppRoutes.CommissionPage },
    //         { key: 'Garage-Commission', entityName: 'Garage Commission', entityUrl: AppRoutes.GarageCommission },
    //     ],
    // },
    // { key: 'item-10', entityName: 'Coupons', entityUrl: AppRoutes.CouponsPage },
    // { key: 'item-11', entityName: 'City', entityUrl: AppRoutes.City },
    // { key: 'item-12', entityName: 'Guidelines', entityUrl: AppRoutes.Guidelines },
    // { key: 'item-13', entityName: 'Service List', entityUrl: AppRoutes.ServiceList },
    // { key: 'item-14', entityName: 'Referral', entityUrl: AppRoutes.ReferralCoupons },
];
type ServiceTagsOption = { id: string; value: string; name: string }[];
export const serviceTagOption: ServiceTagsOption = [
    { id: '1', value: 'value-added-service', name: 'Value Added Service' },
    { id: '2', value: 'schedule-service', name: 'Schedule Service' },
    { id: '3', value: 'other', name: 'Other' },
];
export enum NextFrom {
    BASIC_DETAIL = 'basic-detail',
    BANK_DETAIL = 'bank-detail',
    MARKETING = 'marketing',
    STAFF = 'staff',
    SERVICE = 'service',
}
export enum GarageStatus {
    ACCEPTED = 'accepted',
    REJECTED = 'rejected',
    NEW = 'new',
    BLACKLISTED = 'blacklisted',
}

export enum PickupStatus {
    PICKUP_SCHEDULED = 'pickup-scheduled',
    VEHICLE_IN_TRANSIT = 'vehicle-in-transit',
    VEHICLE_REACHED = 'vehicle-reached',
}

export { entityArray };
