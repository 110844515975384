import { errorMsg } from '../constants/messages';

const QueriesFormData = [
    {
        title: 'Queries Details',
        name: 'fullName',
        label: 'Full Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Full Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'email',
        label: 'Email',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'contact',
        label: 'Contact',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Contact', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'country',
        label: 'Country',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Country' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'company',
        label: 'Company',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Company' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'subject',
        label: 'Subject',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Subject' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'message',
        label: 'Message',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Message' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { QueriesFormData };
