import { errorMsg } from '../constants/messages';

const ProductCategoriesFormData = [
    {
        title: 'Product Category Details',
        name: 'categoryName',
        label: 'Category Name',
        fieldType: {
            name: 'select',
            options: [],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Category Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'desktopPngUrl',
        label: 'Desktop Image(Png)',
        fieldType: {
            name: 'documitraFile',
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Image', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'desktopWebPUrl',
        label: 'Desktop Image(WebP)',
        fieldType: {
            name: 'documitraFile',
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Image', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'mobilePngUrl',
        label: 'Mobile Image(Png)',
        fieldType: {
            name: 'documitraFile',
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Mobile Image(Png)', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'mobileWebPUrl',
        label: 'Mobile Image(WebP)',
        fieldType: {
            name: 'documitraFile',
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Mobile Image(WebP)', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: false,
        isAccordian: true,
    },
];

export { ProductCategoriesFormData };
