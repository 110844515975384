// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-40%, 30%); }

.ant-modal-content {
  width: 500px !important;
  min-height: 300px !important; }

#submitBtn {
  background: #586f80;
  border-radius: 4px;
  height: 28px;
  color: white;
  font-weight: 600;
  text-align: center;
  border: none !important;
  transition: all 0.5s linear;
  margin: 0px 10px;
  padding: 0 12px; }
`, "",{"version":3,"sources":["webpack://./src/components/FaqInputModal/style.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,QAAQ;EACR,SAAS;EACT,+BAA+B,EAAA;;AAGnC;EACI,uBAAuB;EACvB,4BAA4B,EAAA;;AAGhC;EACI,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,2BAA2B;EAC3B,gBAAgB;EAChB,eAAe,EAAA","sourcesContent":[".custom-modal {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-40%, 30%);\n}\n\n.ant-modal-content {\n    width: 500px !important;\n    min-height: 300px !important;\n}\n\n#submitBtn {\n    background: #586f80;\n    border-radius: 4px;\n    height: 28px;\n    color: white;\n    font-weight: 600;\n    text-align: center;\n    border: none !important;\n    transition: all 0.5s linear;\n    margin: 0px 10px;\n    padding: 0 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
