import { errorMsg } from '../constants/messages';

const ClientFormData = [
    {
        title: 'Client Details',
        name: 'categoryName',
        label: 'Category Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Category Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'companyName',
        label: 'Company Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Company Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'details',
        label: 'Details',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Details', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'logo',
        label: 'Logo',
        fieldType: {
            name: 'documitraFile',
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Logo', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { ClientFormData };
