/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Tooltip, Upload, message } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import './style.scss';
import { BASE_API_URL } from '../../env';

const MultipleImageUpload = (props: any) => {
    const { formInput, index, form, isCreateMode } = props;
    const [fileList, setFileList] = useState<any>([]);
    const [fileNameInIndex, setFileNameInIndex] = useState<any>({});
    const [isDelete, setIsdelete] = useState(false);

    const formFieldValue = form.getFieldValue('images');

    const onChange = (info: any, index: string) => {
        const { fileList, file } = info;
        const { status, size } = file;

        switch (status) {
            case 'uploading': {
                const fileSizeCheck = (size: number, allowedSize?: number) => {
                    if (!allowedSize) return true;

                    return size / 1024 < allowedSize;
                };

                const isUploadAllowed = fileSizeCheck(size, formInput.fieldType.maxFileSize);

                if (isUploadAllowed) {
                    setFileList(fileList);
                }
                break;
            }

            case 'removed': {
                form.getFieldValue('images');
                setFileList(fileList);
                setFileNameInIndex((prev: any) => {
                    delete prev[index];
                    return { ...prev };
                });
                setIsdelete(true);

                break;
            }

            case 'done': {
                const res = fileList.map((value: any) => {
                    const obj: any = { status: 'done' };
                    if (value.url) {
                        obj['url'] = value.url;
                    } else {
                        obj['url'] = value.response.data;
                    }
                    obj['name'] = index;
                    return obj;
                });
                setFileNameInIndex((prev: any) => ({ ...prev, [index]: index }));
                setFileList((prev: any) => [...prev, ...res]);
                message.success(`${info.file.name} file uploaded successfully.`);
                break;
            }

            case 'error': {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    const onPreview = async (file: any) => {
        let src = file?.response?.data;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none', width: '400px' }} type='button'>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload media</div>
        </button>
    );

    // do not disturb this function
    const setFileValue = () => {
        const payloadUrlName = ['desktopPngUrl', 'desktopWebPUrl', 'mobilePngUrl', 'mobileWebPUrl'];
        const data: any = [];
        const _fileNameInIndex: any = {};
        const listData = formFieldValue?.map((obj: any, idx: number) => {
            const object1: any = {};
            let index = 0;
            Object.entries(obj).map((ele: any) => {
                const [key, value] = ele;
                if (payloadUrlName.includes(key)) {
                    const object2 = {
                        uid: idx + '' + index,
                        status: 'done',
                        thumbUrl: value,
                        name: key,
                        response: { data: value },
                    };
                    _fileNameInIndex[idx + '' + index] = idx + '' + index;
                    object1['image' + (index + 1)] = [object2];
                    data.push(object2);
                    index = index + 1;
                }
            });
            if (obj['imageInfo']) {
                object1['input' + idx] = obj['imageInfo'];
            }
            return object1;
        });
        form.setFieldsValue({ images: listData });
        return { indexList: _fileNameInIndex, fileList: data };
    };
    useEffect(() => {
        const firstObject = formFieldValue ? formFieldValue[0] : null;

        if (!isCreateMode && !isDelete && firstObject && firstObject['desktopPngUrl']) {
            const { indexList, fileList } = setFileValue();

            // Check if file names have changed before updating state
            const hasFileNamesChanged = Object.keys(indexList).some((key) => indexList[key] !== fileNameInIndex[key]);

            if (hasFileNamesChanged) {
                setFileNameInIndex(indexList);
                setFileList(() => fileList);
            }
        }
    }, [form.getFieldValue('images'), isCreateMode]);

    const tooltipMessages = [
        'Upload desktop PNG image',
        'Upload desktop WebP image',
        'Upload mobile PNG image',
        'Upload mobile WebP image',
    ];

    const getAngleText = (index: number) => {
        const angles = ['1st', '2nd', '3rd', '4th'];
        return `Upload images of ${angles[index] || `${index + 1}th`} angle.`;
    };

    return (
        <Form.List key={index} name={formInput.name}>
            {(fields, { add, remove }, { errors }) => (
                <>
                    {fields.map(({ key, name, ...restField }, index) => (
                        <Form.Item
                            key={key}
                            wrapperCol={{ span: 8, offset: 4 }}
                            labelCol={{ span: 4 }}
                            label={index === 0 ? <div>{formInput.label}</div> : <div></div>}
                            labelAlign='left'
                            colon={false}>
                            <div
                                style={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }}
                                className='imageUploadContainer'>
                                <div>{getAngleText(index)}</div>
                                <div style={{ display: 'flex', columnGap: '8px' }} className='imageUploadContainer'>
                                    {[1, 2, 3, 4].map((num, idx: any) => (
                                        <Tooltip key={num} title={tooltipMessages[idx]} placement='top'>
                                            <Form.Item
                                                key={num}
                                                style={{ marginBottom: '0px', width: '100%' }}
                                                {...restField}
                                                name={[name, `image${num}`]}
                                                valuePropName='fileList'
                                                getValueFromEvent={(e) => {
                                                    if (Array.isArray(e)) {
                                                        return e;
                                                    }
                                                    return e && e.fileList;
                                                }}
                                                rules={[{ required: true, message: 'Please upload an image' }]}>
                                                <Upload
                                                    name={`displayImage`}
                                                    listType='picture-card'
                                                    fileList={fileList}
                                                    action={`${BASE_API_URL}homepage/upload`}
                                                    onChange={(info: any) => onChange(info, index + '' + idx)}
                                                    onPreview={onPreview}>
                                                    {fileNameInIndex[index + '' + idx] ? null : uploadButton}
                                                </Upload>
                                            </Form.Item>
                                        </Tooltip>
                                    ))}
                                    {!formInput.readonly && (
                                        <MinusCircleOutlined
                                            className='dynamic-delete-button offset'
                                            style={{ marginRight: '-50px', marginTop: '30px' }}
                                            onClick={() => remove(name)}
                                        />
                                    )}
                                </div>
                            </div>
                            <Form.Item
                                key={index}
                                style={{ marginBottom: '0px', width: '100%' }}
                                {...restField}
                                name={[name, `input${index}`]}
                                getValueFromEvent={(e) => e.target.value}
                                rules={[{ required: false, message: 'Please Enter image info' }]}>
                                <Input placeholder='Enter image info' />
                            </Form.Item>
                        </Form.Item>
                    ))}
                    {!formInput.readonly && (
                        <Form.Item
                            wrapperCol={{ offset: 8 }}
                            labelCol={{ span: 4 }}
                            style={{ position: 'relative' }}
                            required>
                            <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                                Add {formInput.label}
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    )}
                </>
            )}
        </Form.List>
    );
};

export default MultipleImageUpload;
