/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import {
    SUBCATEGORIES_ENTITY,
    USERS_ENTITY,
    DEFECT_TYPES_ENTITY,
    COMPLAINTS_ENTITY,
    MANAGER_TECHNICIAN_ENTITY,
    ASSETS_ENTITY,
    LOCATIONS_ENTITY,
    CATEGORIES_ENTITY,
    CONTACT_INFO,
    APPLICATION_FORM_PACKAGE_PLANS,
    APPLICATION_FORM_QUESTIONS,
    APPLICATION_FORM_SERVICES,
    APPLICATION_FORM_PACKAGES,
    SERVICE_PROCESS_STEPS,
    SERVICE_PROCESS_DETAILS,
    SERVICE_PROCESS_SUBTITLE,
    MODELS_ENTITY,
    CATEGORY_SUBCATEGORIES_ENTITY,
    COUPONS_ENTITY,
    CATEGORY_CATEGORIES_ENTITY,
    MAKES_ENTITY,
    THIRD_PARTY_CHARGES_ENTITY,
    TOWING_CHARGES_ENTITY,
    GARAGE_COMMISSION_TYPE,
    COMMISSION_LIST_ENTITY,
    FEATURED_COLLECTION,
    CURATED_SPACES,
    CUSTOMIZABLE_PRODUCTS,
    MOODBOARD_SECTIONS,
    COLLABORATION_SECTIONS,
    CATEGORY,
    COLLABS,
    JOB_POSTING,
    PRODUCTS,
    STORIES,
    PRODUCT_CATEGORIES,
    CATALOGUE,
    ABOUT,
    SPACES,
    USERS,
    CONTACT,
    HERO_IMAGE,
    HEADER_INFO,
} from '../constants/EntityName';

// It is hard-coded data and needs to be updated whenever the id of Categories change on backend
export const categoryIds: { [key: string]: string } = {
    fresh: '1',
    kitchen: '2',
};

export const getOutgoingPayload = (
    entityName: string,
    rawPayload: any,
    additionalData: any,
    extraPayloadData?: any,
    incomingFormData?: any,
    nestedEntityName?: string,
    isCreateMode?: boolean,
) => {
    switch (entityName) {
        case FEATURED_COLLECTION:
        case CURATED_SPACES:
        case CUSTOMIZABLE_PRODUCTS: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                const payloadUrlName: any = {
                    1: 'desktopPngUrl',
                    2: 'desktopWebPUrl',
                    3: 'mobilePngUrl',
                    4: 'mobileWebPUrl',
                };
                if (key === 'images') {
                    acc['images'] = value.map((item: any, i: number) => {
                        const obj: any = {};
                        if (item['input' + i]) {
                            obj['imageInfo'] = item['input' + i];
                        }
                        [1, 2, 3, 4].forEach((num: any) => {
                            obj[payloadUrlName[num]] = item['image' + num][0]['response']['data'];
                        });
                        return obj;
                    });
                    return acc;
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case MOODBOARD_SECTIONS: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (
                    key === 'desktopPngUrl' ||
                    key === 'desktopWebPUrl' ||
                    key === 'mobilePngUrl' ||
                    key === 'mobileWebPUrl'
                ) {
                    if (!acc?.images) {
                        acc['images'] = [
                            {
                                [key]: value,
                            },
                        ];
                    } else {
                        acc.images[0][key] = value;
                    }
                    return acc;
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case COLLABORATION_SECTIONS: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (
                    key === 'desktopPngUrl' ||
                    key === 'desktopWebPUrl' ||
                    key === 'mobilePngUrl' ||
                    key === 'mobileWebPUrl'
                ) {
                    if (!acc?.images) {
                        acc['images'] = [
                            {
                                [key]: value,
                            },
                        ];
                    } else {
                        acc.images[0][key] = value;
                    }
                    return acc;
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case CATEGORY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (
                    key === 'desktopPngUrl' ||
                    key === 'desktopWebPUrl' ||
                    key === 'mobilePngUrl' ||
                    key === 'mobileWebPUrl'
                ) {
                    if (!acc?.headerImage) {
                        acc['headerImage'] = [
                            {
                                [key]: value,
                            },
                        ];
                    } else {
                        acc.headerImage[0][key] = value;
                    }
                    return acc;
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case COLLABS: {
            const obj = Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                const payloadUrlName: any = {
                    1: 'desktopPngUrl',
                    2: 'desktopWebPUrl',
                    3: 'mobilePngUrl',
                    4: 'mobileWebPUrl',
                };
                if (key === 'images') {
                    acc['images'] = value.map((item: any, i: number) => {
                        const obj: any = {};
                        if (item['input' + i]) {
                            obj['imageInfo'] = item['input' + i];
                        }
                        [1, 2, 3, 4].forEach((num: any) => {
                            obj[payloadUrlName[num]] = item['image' + num][0]['response']['data'];
                        });
                        return obj;
                    });
                    return acc;
                } else if (key === 'title' || key === 'description') {
                    if (!acc?.content) {
                        acc['content'] = [
                            {
                                [key]: value,
                                'authorDetails': {},
                            },
                        ];
                    } else {
                        acc.content[0][key] = value;
                    }
                } else if (key === 'name' || key === 'designation') {
                    if (acc?.content) {
                        if (!acc.content[0]['authorDetails']) {
                            acc.content[0]['authorDetails'] = {};
                        }
                        acc.content[0]['authorDetails'][key] = value;
                    } else {
                        acc['content'] = [
                            {
                                'authorDetails': {
                                    [key]: value,
                                },
                            },
                        ];
                    }
                } else if (
                    key === 'heroDesktopPngUrl' ||
                    key === 'heroDesktopWebPUrl' ||
                    key === 'heroMobilePngUrl' ||
                    key === 'heroMobileWebPUrl' ||
                    key === 'heroImageInfo'
                ) {
                    acc['heroImage'] = {
                        'desktopPngUrl': rawPayload.heroDesktopPngUrl,
                        'desktopWebPUrl': rawPayload.heroDesktopWebPUrl,
                        'mobilePngUrl': rawPayload.heroMobilePngUrl,
                        'mobileWebPUrl': rawPayload.heroMobileWebPUrl,
                        'imageInfo': rawPayload?.heroImageInfo?.length ? rawPayload.heroImageInfo : '',
                    };
                } else if (key === 'featuredProducts') {
                    acc['featuredProducts'] = value.map((id: any) => {
                        if (typeof id === 'string') {
                            return id;
                        } else {
                            return id.value;
                        }
                    });
                    return acc;
                }

                const obj = { ...acc, [key]: value };
                delete obj['desktopPngUrl'];
                delete obj['desktopWebPUrl'];
                delete obj['mobilePngUrl'];
                delete obj['mobileWebPUrl'];
                delete obj['imageInfo'];

                delete obj['name'];
                delete obj['designation'];
                delete obj['title'];
                delete obj['description'];

                delete obj['heroDesktopPngUrl'];
                delete obj['heroDesktopWebPUrl'];
                delete obj['heroMobilePngUrl'];
                delete obj['heroMobileWebPUrl'];
                delete obj['heroImageInfo'];

                if (Array.isArray(value)) {
                    value = value.map((o: any) => {
                        delete o['_id'];
                        return {
                            ...o,
                        };
                    });
                }

                return obj;
            }, {});

            return obj;
        }

        case STORIES: {
            const obj = Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                const payloadUrlName: any = {
                    1: 'desktopPngUrl',
                    2: 'desktopWebPUrl',
                    3: 'mobilePngUrl',
                    4: 'mobileWebPUrl',
                };
                if (key === 'images') {
                    acc['images'] = value.map((item: any, i: number) => {
                        const obj: any = {};
                        if (item['input' + i]) {
                            obj['imageInfo'] = item['input' + i];
                        }
                        [1, 2, 3, 4].forEach((num: any) => {
                            obj[payloadUrlName[num]] = item['image' + num][0]['response']['data'];
                        });
                        return obj;
                    });
                    return acc;
                } else if (key === 'title' || key === 'description') {
                    if (!acc?.content) {
                        acc['content'] = [
                            {
                                [key]: value,
                                'authorDetails': {},
                            },
                        ];
                    } else {
                        acc.content[0][key] = value;
                    }
                } else if (key === 'name' || key === 'designation') {
                    if (acc?.content) {
                        if (!acc.content[0]['authorDetails']) {
                            acc.content[0]['authorDetails'] = {};
                        }
                        acc.content[0]['authorDetails'][key] = value;
                    } else {
                        acc['content'] = [
                            {
                                'authorDetails': {
                                    [key]: value,
                                },
                            },
                        ];
                    }
                } else if (
                    key === 'heroDesktopPngUrl' ||
                    key === 'heroDesktopWebPUrl' ||
                    key === 'heroMobilePngUrl' ||
                    key === 'heroMobileWebPUrl' ||
                    key === 'heroImageInfo'
                ) {
                    acc['heroImage'] = {
                        'desktopPngUrl': rawPayload.heroDesktopPngUrl,
                        'desktopWebPUrl': rawPayload.heroDesktopWebPUrl,
                        'mobilePngUrl': rawPayload.heroMobilePngUrl,
                        'mobileWebPUrl': rawPayload.heroMobileWebPUrl,
                        'imageInfo': rawPayload?.heroImageInfo?.length ? rawPayload.heroImageInfo : '',
                    };
                } else if (key === 'featuredProducts') {
                    acc['featuredProducts'] = value.map((id: any) => {
                        if (typeof id === 'string') {
                            return id;
                        } else {
                            return id.value;
                        }
                    });
                    return acc;
                }

                const obj = { ...acc, [key]: value };
                delete obj['desktopPngUrl'];
                delete obj['desktopWebPUrl'];
                delete obj['mobilePngUrl'];
                delete obj['mobileWebPUrl'];
                delete obj['imageInfo'];

                delete obj['name'];
                delete obj['designation'];
                delete obj['title'];
                delete obj['description'];

                delete obj['heroDesktopPngUrl'];
                delete obj['heroDesktopWebPUrl'];
                delete obj['heroMobilePngUrl'];
                delete obj['heroMobileWebPUrl'];
                delete obj['heroImageInfo'];

                if (Array.isArray(value)) {
                    value = value.map((o: any) => {
                        delete o['_id'];
                        return {
                            ...o,
                        };
                    });
                }

                return obj;
            }, {});

            return obj;
        }

        case PRODUCT_CATEGORIES: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (
                    key === 'desktopPngUrl' ||
                    key === 'desktopWebPUrl' ||
                    key === 'mobilePngUrl' ||
                    key === 'mobileWebPUrl'
                ) {
                    if (!acc?.images) {
                        acc['images'] = [
                            {
                                [key]: value,
                            },
                        ];
                    } else {
                        acc.images[0][key] = value;
                    }
                    return acc;
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case JOB_POSTING: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (
                    key === 'description' ||
                    key === 'responsibilities' ||
                    key === 'requirements' ||
                    key === 'benefits'
                ) {
                    acc[key] = [value].flat(1);
                    return acc;
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case PRODUCTS: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                const payloadUrlName: any = {
                    1: 'desktopPngUrl',
                    2: 'desktopWebPUrl',
                    3: 'mobilePngUrl',
                    4: 'mobileWebPUrl',
                };

                if (key === 'images') {
                    acc['images'] = value.map((item: any, i: number) => {
                        const obj: any = {};
                        if (item['input' + i]) {
                            obj['imageInfo'] = item['input' + i];
                        }
                        [1, 2, 3, 4].forEach((num: any) => {
                            obj[payloadUrlName[num]] = item['image' + num][0]['response']['data'];
                        });
                        return obj;
                    });
                    return acc;
                }

                if (key === 'availableColors') {
                    return { ...acc, [key]: value };
                }

                if (Array.isArray(value)) {
                    value = value.map((o: any) => {
                        delete o['_id'];
                        return {
                            ...o,
                        };
                    });
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case CONTACT: {
            return {
                headOfficeAddress: rawPayload.headOfficeAddress,
                factoryAddress: rawPayload.factoryAddress,
                enquries: {
                    email: rawPayload.enEmail,
                    mobile: rawPayload.enMobile,
                },
                customerCare: {
                    email: rawPayload.email,
                    mobile: rawPayload.mobile,
                },
                showroomDetails: [
                    {
                        location: rawPayload.location,
                        address: rawPayload.address,
                        email: rawPayload.shEmail,
                        mobile: rawPayload.shMobile,
                        timing: rawPayload.timing,
                        images: [
                            {
                                desktopPngUrl: rawPayload.desktopPngUrl,
                                desktopWebPUrl: rawPayload.desktopWebPUrl,
                                mobilePngUrl: rawPayload.mobilePngUrl,
                                mobileWebPUrl: rawPayload.mobileWebPUrl,
                                imageInfo: rawPayload.imageInfo,
                            },
                        ],
                    },
                ],
            };
        }

        case CATALOGUE:
        case ABOUT: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                const payloadUrlName: any = {
                    1: 'desktopPngUrl',
                    2: 'desktopWebPUrl',
                    3: 'mobilePngUrl',
                    4: 'mobileWebPUrl',
                };
                if (key === 'images') {
                    acc['images'] = value.map((item: any, i: number) => {
                        const obj: any = {};
                        if (item['input' + i]) {
                            obj['imageInfo'] = item['input' + i];
                        }
                        [1, 2, 3, 4].forEach((num: any) => {
                            obj[payloadUrlName[num]] = item['image' + num][0]['response']['data'];
                        });
                        return obj;
                    });
                    return acc;
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case SPACES: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                const payloadUrlName: any = {
                    1: 'desktopPngUrl',
                    2: 'desktopWebPUrl',
                    3: 'mobilePngUrl',
                    4: 'mobileWebPUrl',
                };
                if (key === 'images') {
                    acc['images'] = value.map((item: any, i: number) => {
                        const obj: any = {};
                        if (item['input' + i]) {
                            obj['imageInfo'] = item['input' + i];
                        }
                        [1, 2, 3, 4].forEach((num: any) => {
                            obj[payloadUrlName[num]] = item['image' + num][0]['response']['data'];
                        });
                        return obj;
                    });
                    return acc;
                } else if (key === 'products') {
                    acc['products'] = value.map((id: any) => {
                        if (typeof id === 'string') {
                            return id;
                        } else {
                            return id.value;
                        }
                    });
                    return acc;
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case USERS: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'permissions') {
                    acc['permissions'] = value
                        .map((permission: any) => {
                            const arr = [];
                            if (permission['create']) {
                                arr.push('create');
                            }
                            if (permission['read']) {
                                arr.push('read');
                            }
                            if (permission['update']) {
                                arr.push('update');
                            }
                            if (permission['delete']) {
                                arr.push('delete');
                            }
                            if (arr.length) {
                                return {
                                    subject: permission['name'],
                                    actions: arr,
                                };
                            } else {
                                false;
                            }
                        })
                        .filter((Permission: any) => Permission);
                    return acc;
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case HEADER_INFO: {
            let NAME = '';
            const obj = Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'name') {
                    NAME = value;
                }
                if (
                    key === 'desktopPngUrl' ||
                    key === 'desktopWebPUrl' ||
                    key === 'mobilePngUrl' ||
                    key === 'mobileWebPUrl' ||
                    key === 'imageInfo'
                ) {
                    acc[`${acc.name}`] = {
                        ...acc[NAME],

                        heroImage: [
                            {
                                desktopPngUrl: rawPayload.desktopPngUrl,
                                desktopWebPUrl: rawPayload.desktopWebPUrl,
                                mobilePngUrl: rawPayload.mobilePngUrl,
                                mobileWebPUrl: rawPayload.mobileWebPUrl,
                                imageInfo: rawPayload.imageInfo,
                            },
                        ],
                    };
                    return acc;
                }
                if (key === 'description') {
                    acc[NAME] = {
                        ...acc[NAME],
                        description: value,
                    };
                    return acc;
                }
                delete acc['imageInfo'];
                return { ...acc, [key]: value };
            }, {});
            delete obj['name'];
            return obj;
        }

        case USERS_ENTITY: {
            return Object.entries(rawPayload).reduce(
                (acc: any, [key, value]: any) =>
                    // if (key === 'role') {
                    //     return { ...acc, [key]: { id: value } };
                    // }
                    ({ ...acc, [key]: value }),
                {},
            );
        }

        case COMMISSION_LIST_ENTITY: {
            const { charge, commissionType } = rawPayload;

            if (!isCreateMode) {
                return {
                    charge: Number(charge),
                    commissionType: commissionType,
                };
            } else {
                return {
                    ...rawPayload,
                };
            }
        }

        case THIRD_PARTY_CHARGES_ENTITY:
        case TOWING_CHARGES_ENTITY: {
            const { charge, chargeType } = rawPayload;

            const payload = {
                charge: Number(charge),
                chargeType: chargeType,
            };

            return {
                ...payload,
            };
        }

        case MODELS_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'make') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case CATEGORY_SUBCATEGORIES_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'category') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case APPLICATION_FORM_SERVICES: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'parameters') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case APPLICATION_FORM_PACKAGE_PLANS: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'features') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                if (key === 'documents') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case CONTACT_INFO: {
            return Object.entries(rawPayload).reduce(
                (acc: any, [key, value]: any) => {
                    if (key === 'facebook') {
                        // Assuming value is a string representing the social media link
                        const socialMediaLinks = {
                            ...acc.socialMediaLinks,
                            [key]: `${rawPayload.facebook}`,
                        };
                        return { ...acc, socialMediaLinks };
                    }
                    if (key === 'twitter') {
                        // Assuming value is a string representing the social media link
                        const socialMediaLinks = {
                            ...acc.socialMediaLinks,
                            [key]: `${rawPayload.twitter}`,
                        };
                        return { ...acc, socialMediaLinks };
                    }
                    return { ...acc, [key]: value };
                },
                {
                    contactNumbers: ['string'],
                    email: 'string',
                    socialMediaLinks: {},
                },
            );
        }

        case GARAGE_COMMISSION_TYPE: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'garageName') {
                    return { ...acc, ['garage']: { id: value } };
                }
                if (key === 'commission') {
                    return { ...acc, ['commission']: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case APPLICATION_FORM_QUESTIONS: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'service') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case SERVICE_PROCESS_DETAILS: {
            const { description, sequence, heading, service } = rawPayload;
            const payload = {
                heading: heading,
                description: description,
                sequence: sequence,
            };
            if (isCreateMode) {
                return {
                    ...payload,
                    service: { id: service },
                };
            } else {
                return {
                    ...payload,
                };
            }
        }

        case SERVICE_PROCESS_SUBTITLE: {
            const { description, sequence, heading, title } = rawPayload;
            const payload = {
                heading: heading,
                description: description,
                sequence: sequence,
            };
            if (isCreateMode) {
                return {
                    ...payload,
                    title: { id: title },
                };
            } else {
                return {
                    ...payload,
                };
            }
        }

        case SERVICE_PROCESS_STEPS: {
            const { description, sequence, service } = rawPayload;
            const payload = {
                description: description,
                sequence: sequence,
            };
            if (isCreateMode) {
                return {
                    ...payload,
                    service: { id: service },
                };
            } else {
                return {
                    ...payload,
                };
            }
        }

        case COUPONS_ENTITY: {
            const { name, description, couponCode, discount, validFrom, validTo, discountType, status, usesPerUser } =
                rawPayload;
            const formatDate = (date: any) => {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            };

            // Convert validFrom and validTo to local dates and format
            const formattedValidFrom = formatDate(new Date(validFrom));
            const formattedValidTo = formatDate(new Date(validTo));

            const payload = {
                name: name,
                description: description,
                couponCode: couponCode,
                discount: discount,
                validFrom: formattedValidFrom,
                validTo: formattedValidTo,
                discountType: discountType,
                status: status,
                usesPerUser: Number(usesPerUser),
            };
            return {
                ...payload,
            };
        }

        case APPLICATION_FORM_PACKAGES: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'serviceAttributes') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                if (key === 'plans') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                if (key === 'service') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case ASSETS_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'locationType') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case LOCATIONS_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'locationType') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case SUBCATEGORIES_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'manager') {
                    return { ...acc, [key]: { id: value } };
                }
                if (key === 'category') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case CATEGORIES_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'asset') {
                    return { ...acc, [key]: { id: value } };
                }
                if (key === 'manager') {
                    return { ...acc, [key]: { id: value } };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case COMPLAINTS_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'defectType') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case MANAGER_TECHNICIAN_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'technicians') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                if (key === 'manager') {
                    return { ...acc, [key]: { id: value } };
                }
            }, {});
        }
    }

    const entityNamesArray = entityName?.split('/');
    const requiredEntityName = entityNamesArray[entityNamesArray.length - 1];

    switch (`/${requiredEntityName}`) {
        default:
            return rawPayload;
    }
};
