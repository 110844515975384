import { errorMsg } from '../constants/messages';

const ContactFormData = [
    {
        title: 'Contact Details',
        name: 'headOfficeAddress',
        label: 'Head Office Address',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Head Office Address', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'factoryAddress',
        label: 'Factory Address',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Factory Address', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'enEmail',
        label: 'Enqury Email',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Enqury Email', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'enMobile',
        label: 'Enqury Mobile',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Enqury Mobile', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'email',
        label: 'Customer Care Email',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Customer Care Email', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'mobile',
        label: 'Customer Care Mobile',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Customer Care Mobile', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'location',
        label: 'Location',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Location', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'address',
        label: 'Address',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Address', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'shEmail',
        label: 'Showroom Email',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Showroom Email', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'shMobile',
        label: 'Showroom Mobile',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Showroom Mobile', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'timing',
        label: 'Timing',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Timing', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'desktopPngUrl',
        label: 'Desktop Image(Png)',
        fieldType: {
            name: 'documitraFile',
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Image', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'desktopWebPUrl',
        label: 'Desktop Image(WebP)',
        fieldType: {
            name: 'documitraFile',
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Desktop Image(WebP)', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'mobilePngUrl',
        label: 'Mobile Image(Png)',
        fieldType: {
            name: 'documitraFile',
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Mobile Image(Png)', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'mobileWebPUrl',
        label: 'Mobile Image(WebP)',
        fieldType: {
            name: 'documitraFile',
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Mobile Image(WebP)', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'imageInfo',
        label: 'Image Info',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Image Info', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { ContactFormData };
