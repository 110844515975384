/* eslint-disable no-unused-vars */
import { Button, Form, Input, Modal } from 'antd';
import './style.scss';

type ModalComponentProps = {
    close: (data: any) => any;
    data: any;
    setData: any;
    handleSubmit: any;
    label1?: string;
    label2?: string;
};

type FieldType = {
    question?: string;
    answer?: string;
};

const FaqInputModal: React.FC<ModalComponentProps> = ({ close, data, setData, handleSubmit, label1, label2 }) => {
    const handleCancel = () => {
        close(false);
    };

    const onChangeInput = (e: any) => {
        const input = e.target;
        setData((prev: any) => ({ ...prev, [input.name]: input.value }));
    };

    return (
        <Modal
            className='custom-modal'
            title='FAQ'
            visible={true} // Set this to a state variable that toggles the modal visibility
            onCancel={() => close(false)}
            footer={[
                <Button key='cancel' onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key='submit' type='primary' onClick={handleSubmit} id='submitBtn'>
                    Submit
                </Button>,
            ]}>
            <label>{label1}</label>
            <Input onChange={onChangeInput} value={data?.question} name='question' placeholder={`Enter ${label1}`} />
            <label>{label2}</label>
            <Input onChange={onChangeInput} value={data?.answer} name='answer' placeholder={`Enter ${label2}`} />
        </Modal>
    );
};

export default FaqInputModal;
