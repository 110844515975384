import { errorMsg } from '../constants/messages';

const JobApplicationFormData = [
    {
        title: 'Job Application Details',
        name: 'fullName',
        label: 'Full Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Full Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'formDescription',
        label: 'Form Description',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Form Description' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'email',
        label: 'Email',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'contact',
        label: 'Contact',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Contact' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'company',
        label: 'Company',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Company' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'preferredLocation',
        label: 'Preferred Location',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Preferred Location' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'cv',
        label: 'CV',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'CV' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { JobApplicationFormData };
