/* eslint-disable no-unused-vars */
import { Button, Form, Input, Modal } from 'antd';
import './style.scss';

type ModalComponentProps = {
    close: (data: any) => any;
    data: any;
    setData: any;
    handleSubmit: any;
    buttonLabel?: string;
    label?: string;
};

type FieldType = {
    question?: string;
    answer?: string;
};

const FaqSectionModal: React.FC<ModalComponentProps> = ({ close, data, setData, handleSubmit, buttonLabel, label }) => {
    const handleCancel = () => {
        close(false);
    };

    const onChangeInput = (e: any) => {
        const input = e.target;
        setData((prev: any) => ({ ...prev, [input.name]: input.value }));
    };

    return (
        <Modal
            className='custom-modal'
            title='FAQ Section'
            visible={true}
            onCancel={() => close(false)}
            footer={[
                <Button key='cancel' onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key='submit' type='primary' onClick={handleSubmit} id='submitBtn'>
                    {buttonLabel ? buttonLabel : 'Next'}
                </Button>,
            ]}>
            <label>{label}</label>
            <Input
                onChange={onChangeInput}
                value={data?.sectionName}
                name='sectionName'
                placeholder={`Enter ${label}`}
            />
        </Modal>
    );
};

export default FaqSectionModal;
