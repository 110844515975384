import { customErrorMsg, errorMsg } from '../constants/messages';
import { REGEX_PASSWORD, REGEX_PHONE_NUMBER } from '../utils/Regexp/Regex';

const UsersFormData = [
    {
        title: 'Users Details',
        name: 'name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'phone',
        label: 'Phone',
        fieldType: {
            name: 'text',
        },
        required: true,
        rules: [{ pattern: REGEX_PHONE_NUMBER, message: customErrorMsg('phone number') }],
        errorMsg: errorMsg,
        tableMeta: { title: 'Phone', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'isAdmin',
        label: 'IsAdmin',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'isAdmin', allowAddRecordClick: true },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'email',
        label: 'Email',
        fieldType: {
            name: 'email',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'password',
        label: 'Password',
        fieldType: {
            name: 'text',
        },
        required: true,
        rules: [
            {
                pattern: REGEX_PASSWORD,
                message:
                    'Password must contain uppercase character, lowercase character, symbol, and number and min length of 8',
            },
        ],
        errorMsg: errorMsg,
        tableMeta: { title: 'Password', columnType: 'password' },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
    {
        name: 'permissions',
        label: '',
        fieldType: {
            name: 'permissions',
        },
        errorMsg: errorMsg,
        tableMeta: { title: 'permissions', columnType: 'permissions' },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
];

export { UsersFormData };
