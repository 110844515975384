// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showroomContainer {
  display: block;
  padding: 20px 30px;
  min-height: 100vh; }
`, "",{"version":3,"sources":["webpack://./src/components/Showroom/style.scss"],"names":[],"mappings":"AAAA;EACI,cAAc;EACd,kBAAkB;EAClB,iBAAiB,EAAA","sourcesContent":[".showroomContainer {\n    display: block;\n    padding: 20px 30px;\n    min-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
