// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-40%, 30%); }

.ant-modal-content {
  width: 500px !important;
  min-height: 300px !important;
  border-radius: 10px !important;
  padding: 30px; }
`, "",{"version":3,"sources":["webpack://./src/components/FaqSectionModal/style.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,QAAQ;EACR,SAAS;EACT,+BAA+B,EAAA;;AAGnC;EACI,uBAAuB;EACvB,4BAA4B;EAC5B,8BAA8B;EAC9B,aAAa,EAAA","sourcesContent":[".custom-modal {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-40%, 30%);\n}\n\n.ant-modal-content {\n    width: 500px !important;\n    min-height: 300px !important;\n    border-radius: 10px !important;\n    padding: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
