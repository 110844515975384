import { errorMsg } from '../constants/messages';

const ProductsFormData = [
    {
        title: 'Product Details',
        name: 'name',
        label: 'Product name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Product name',
            allowAddRecordClick: true,
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'description',
        label: 'Product description',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Product description', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'category',
        label: 'Category',
        fieldType: {
            name: 'select',
            options: [],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Category', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'subCategory',
        label: 'Sub Category',
        fieldType: {
            name: 'select',
            options: [],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Sub Category', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'productType',
        label: 'Product Type',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Product Type' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'dimensions',
        label: 'Dimensions',
        fieldType: {
            name: 'multipleTwinInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Dimensions' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'productInformation',
        label: 'Product Information',
        fieldType: {
            name: 'multipleTwinInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Product Information' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'materials',
        label: 'Materials',
        fieldType: {
            name: 'multipleTwinInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Materials' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'downloads',
        label: 'Downloads',
        fieldType: {
            name: 'multipleTwinInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Downloads' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'color',
        label: 'Color',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Color' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'availableColors',
        label: 'Available Colors',
        fieldType: {
            name: 'multipleTextInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Available Colors' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'images',
        label: 'Images',
        fieldType: {
            name: 'multiImageUpload',
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Image', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'desktopPngUrl',
        label: 'Desktop Image(Png)',
        fieldType: {
            name: 'documitraFile',
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Image', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'poly9Url',
        label: 'Poly9Url',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Poly9Url' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'featured',
        label: 'Featured',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Featured' },
        isTableHeader: false,
        isAccordian: true,
    },
];

export { ProductsFormData };
