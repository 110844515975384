import { errorMsg } from '../constants/messages';

const SubCategoryFormData = [
    {
        title: 'Sub Category Details',
        name: 'name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'subCategoryId',
        label: 'Sub Category Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Sub Category Id', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'parentId',
        label: 'Category',
        fieldType: {
            name: 'select',
            options: [],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Category', allowAddRecordClick: true },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'status',
        label: 'Status',
        fieldType: {
            name: 'select',
            options: [
                { name: 'Active', value: 'Active' },
                { name: 'Inactive', value: 'Inactive' },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Status', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { SubCategoryFormData };
