import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Sidebar from '../../components/SideBar';
import { AppRoutes, entityArray } from '../../constants/AppConstant';
import SubCategoriesPage from '../SubCategoriesPage';
import ComplaintStatusPage from '../ComplaintStatus';
import LocationPage from '../Location';
import ComplaintsPage from '../ComplaintsPage';
import ManagerTechnician from '../ManagerTechnician';
import AssetsPage from '../Assets';
import LocationTypesPage from '../LocationTypes';
import ServicePage from '../ServicePage';
import ReviewsPage from '../ReviewsPage';
import ContactInfoPage from '../ContactInfo';
import ContactFormPage from '../ContactFormPage';
import ParameterFormService from '../ApplicationFormMaster/FormParameters';
import ApplicationFormService from '../ApplicationFormMaster/FormServices';
import ApplicationFormQuestionPage from '../ApplicationFormMaster/FormQuestions';
import PackageServiceAttributesFormService from '../ApplicationFormMaster/FormPackageServiceAttributes';
import FormPackageDocuments from '../ApplicationFormMaster/FormPackageDocuments';
import FormPackageFeatures from '../ApplicationFormMaster/FormPackageFeatures';
import ApplicationFormPackagePlans from '../ApplicationFormMaster/FormPackagePlans';
import ApplicationFormPackagesPage from '../ApplicationFormMaster/FormPackagesPage';
import MakesPage from '../MakesPage';
import CityPage from '../CityPage';
import ModelsPage from '../ModelsPage';
import CategoryCategoriesPage from '../CategoryCategories';
import CategorySubcategoriesPage from '../CategorySubcategories';
import CouponsPage from '../CouponsPage';
import OrderList from '../OrderList';
import PickUpManagement from '../PickUpManagement';
import GarageManagement from '../GarageManagement';
import GarageDetail from '../../components/GarageDetail';
import UsersList from '../UserList';
import Guidelines from '../Guidelines';
import PaymentPage from '../PaymentPage';
import ThirdPartyCharges from '../ThirdPartyCharges';
import TowingCharges from '../TowingCharges';
import CommissionListPage from '../CommissionListPage';
import GarageCommissionPage from '../GarageCommissionPage';
import ServiceList from '../ServiceList';
import ReferralCouponPage from '../ReferralCouponPage';
import FeaturedCollection from '../FeaturedCollection';
import CuratedSpaces from '../CuratedSpaces';
import CustomizableProducts from '../CustomizableProducts';
import Users from '../Users';
import MoodboardSections from '../MoodboardSections';
import Products from '../Products';
import ProductCategories from '../ProductCategories';

import './styles.scss';
import CollaborationSections from '../CollaborationSections';
import Category from '../Category';
import JobApplication from '../JobApplication';
import Collabs from '../Collabs';
import JobPosting from '../JobPosting';
import SubCategory from '../SubCategory';
import Stories from '../Stories';
import Queries from '../Queries';
import Catalogue from '../Catalogue';
import About from '../About';
import Client from '../Client';
import Spaces from '../Spaces';
import Faqs from '../Faqs';
import Contact from '../Contact';
import HeroImage from '../HeroImage';

const Landing = () => {
    const [moduleList] = useState<any>(entityArray);

    return (
        <Router>
            <div className='landing-box'>
                <Sidebar title='Amardeep' entityArr={moduleList} />
                <div className='landing-box-content'>
                    <Switch>
                        <Route exact path='/' component={Users} />
                        <Route exact path={AppRoutes.Users} component={Users} />

                        <Route exact path={`${AppRoutes.Users}`}>
                            <Users />
                        </Route>

                        <Route exact path={`${AppRoutes.FeaturedCollection}`}>
                            <FeaturedCollection />
                        </Route>
                        <Route exact path={`${AppRoutes.CuratedSpaces}`}>
                            <CuratedSpaces />
                        </Route>
                        <Route exact path={`${AppRoutes.CustomizableProducts}`}>
                            <CustomizableProducts />
                        </Route>
                        <Route exact path={`${AppRoutes.MoodboardSections}`}>
                            <MoodboardSections />
                        </Route>
                        <Route exact path={`${AppRoutes.CollaborationSections}`}>
                            <CollaborationSections />
                        </Route>
                        <Route exact path={`${AppRoutes.Category}`}>
                            <Category />
                        </Route>
                        <Route exact path={`${AppRoutes.JobApplication}`}>
                            <JobApplication />
                        </Route>
                        <Route exact path={`${AppRoutes.Collabs}`}>
                            <Collabs />
                        </Route>
                        <Route exact path={`${AppRoutes.JobPosting}`}>
                            <JobPosting />
                        </Route>
                        <Route exact path={`${AppRoutes.SubCategory}`}>
                            <SubCategory />
                        </Route>
                        <Route exact path={`${AppRoutes.Stories}`}>
                            <Stories />
                        </Route>
                        <Route exact path={AppRoutes.Products}>
                            <Products />
                        </Route>
                        <Route exact path={AppRoutes.Queries}>
                            <Queries />
                        </Route>
                        <Route exact path={`${AppRoutes.ProductCategories}`}>
                            <ProductCategories />
                        </Route>
                        <Route exact path={`${AppRoutes.Catalogue}`}>
                            <Catalogue />
                        </Route>
                        <Route exact path={`${AppRoutes.About}`}>
                            <About />
                        </Route>
                        <Route exact path={`${AppRoutes.Client}`}>
                            <Client />
                        </Route>
                        <Route exact path={`${AppRoutes.Spaces}`}>
                            <Spaces />
                        </Route>
                        <Route exact path={`${AppRoutes.Faqs}`}>
                            <Faqs />
                        </Route>
                        <Route exact path={`${AppRoutes.Contact}`}>
                            <Contact />
                        </Route>
                        <Route exact path={`${AppRoutes.HeroImage}`}>
                            <HeroImage />
                        </Route>

                        <Route exact path={`${AppRoutes.City}`}>
                            <CityPage />
                        </Route>
                        <Route exact path={`${AppRoutes.Models}`}>
                            <ModelsPage />
                        </Route>
                        <Route exact path={`${AppRoutes.CategoryCategories}`}>
                            <CategoryCategoriesPage />
                        </Route>
                        <Route exact path={`${AppRoutes.CategorySubCategories}`}>
                            <CategorySubcategoriesPage />
                        </Route>
                        <Route exact path={`${AppRoutes.CouponsPage}`}>
                            <CouponsPage />
                        </Route>
                        <Route exact path={AppRoutes.AllOrderList}>
                            <OrderList />
                        </Route>
                        <Route exact path={`${AppRoutes.Service}`}>
                            <ServicePage />
                        </Route>
                        <Route exact path={`${AppRoutes.Reviews}`}>
                            <ReviewsPage />
                        </Route>
                        <Route exact path={`${AppRoutes.Makes}`}>
                            <MakesPage />
                        </Route>
                        <Route exact path={`${AppRoutes.ContactInfo}`}>
                            <ContactInfoPage />
                        </Route>
                        <Route exact path={`${AppRoutes.ContactForm}`}>
                            <ContactFormPage />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormParameters}`}>
                            <ParameterFormService />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormServices}`}>
                            <ApplicationFormService />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormQuestion}`}>
                            <ApplicationFormQuestionPage />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormPackageServiceAttributes}`}>
                            <PackageServiceAttributesFormService />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormPackageDocuments}`}>
                            <FormPackageDocuments />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormPackageFeatures}`}>
                            <FormPackageFeatures />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormPackagePlans}`}>
                            <ApplicationFormPackagePlans />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormPackages}`}>
                            <ApplicationFormPackagesPage />
                        </Route>

                        <Route exact path={`${AppRoutes.Subcategories}`}>
                            <SubCategoriesPage />
                        </Route>
                        <Route exact path={`${AppRoutes.complainStatus}`}>
                            <ComplaintStatusPage />
                        </Route>
                        <Route exact path={`${AppRoutes.locationType}`}>
                            <LocationTypesPage />
                        </Route>
                        <Route exact path={`${AppRoutes.locations}`}>
                            <LocationPage />
                        </Route>
                        <Route exact path={`${AppRoutes.complaintsPage}`}>
                            <ComplaintsPage />
                        </Route>
                        <Route exact path={`${AppRoutes.managerTechnician}`}>
                            <ManagerTechnician />
                        </Route>
                        <Route exact path={`${AppRoutes.assets}`}>
                            <AssetsPage />
                        </Route>
                        <Route exact path={`${AppRoutes.PickUpManagement}`}>
                            <PickUpManagement />
                        </Route>
                        <Route exact path={`${AppRoutes.GarageManagement}`}>
                            <GarageManagement />
                        </Route>
                        <Route exact path={`${AppRoutes.GarageManagementApplication}`}>
                            <GarageDetail />
                        </Route>
                        <Route exact path={`${AppRoutes.UserList}`}>
                            <UsersList />
                        </Route>
                        <Route exact path={`${AppRoutes.Guidelines}`}>
                            <Guidelines />
                        </Route>
                        <Route exact path={`${AppRoutes.PaymentListing}`}>
                            <PaymentPage />
                        </Route>
                        <Route exact path={AppRoutes.ThirdPartyCharges}>
                            <ThirdPartyCharges />
                        </Route>
                        <Route exact path={AppRoutes.TowingCharges}>
                            <TowingCharges />
                        </Route>
                        <Route exact path={`${AppRoutes.CommissionPage}`}>
                            <CommissionListPage />
                        </Route>
                        <Route exact path={`${AppRoutes.GarageCommission}`}>
                            <GarageCommissionPage />
                        </Route>
                        {/* <Route exact path={`${AppRoutes.Dashboard}`}>
                            <Dashboard />
                        </Route> */}
                        <Route exact path={AppRoutes.ServiceList}>
                            <ServiceList />
                        </Route>
                        <Route exact path={AppRoutes.ReferralCoupons}>
                            <ReferralCouponPage />
                        </Route>
                    </Switch>
                </div>
            </div>
        </Router>
    );
};

export default Landing;
