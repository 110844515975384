/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import './style.scss';

type ModalComponentProps = {
    onDeleteClose: (data: any) => any;
    onDelete: (data: any) => any;
};

const DeleteConfirmModal: React.FC<ModalComponentProps> = ({ onDeleteClose, onDelete }) => {
    const handleCancel = () => {
        onDeleteClose(false);
    };
    return (
        <>
            <div className='modalOverlay'></div>
            <div className='modalContainer'>
                <p>Are you sure you want to DELETE ?</p>
                <div className='deleteModalBtnGroup'>
                    <button onClick={handleCancel}>Cancel</button>
                    <button onClick={onDelete}>OK</button>
                </div>
            </div>
        </>
    );
};

export default DeleteConfirmModal;
