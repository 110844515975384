import { errorMsg } from '../constants/messages';

const AboutFormData = [
    {
        title: 'About Details',
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'multipleTextInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'images',
        label: 'Images',
        fieldType: {
            name: 'multiImageUpload',
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Image', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'desktopPngUrl',
        label: 'Desktop Image(Png)',
        fieldType: {
            name: 'documitraFile',
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Image', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: true,
        isAccordian: false,
    },
];

export { AboutFormData };
