/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Upload, Button, Input, Form, message, TimePicker } from 'antd';
import moment from 'moment';

import { BASE_API_URL } from '../../env';
import callApi from '../../Api/config';
import { CONTACT } from '../../constants/EntityName';
import { REGEX_PHONE_NUMBER } from '../../utils/Regexp/Regex';
import { customErrorMsg } from '../../constants/messages';

import './style.scss';

const Showroom = (props: any) => {
    const { id, closeTab } = props;
    const [form] = Form.useForm();

    const [formData, setFormData] = useState<any>({
        headOfficeAddress: '',
        factoryAddress: '',
        enquries: { email: '', mobile: '' },
        customerCare: { email: '', mobile: '' },
        showroomDetails: [{ location: '', address: '', email: '', mobile: '', timing: '', images: [] }],
    });

    const getContactData = async () => {
        try {
            const res = await callApi(`${CONTACT}/${id}`, 'GET');
            const dataObj = await res?.data?.data;
            dataObj.showroomDetails = dataObj.showroomDetails.map((ele: any) => {
                const { images } = ele;
                const imagesList = Object.entries(images[0])
                    .filter(([key]: any) => key !== 'imageInfo')
                    .map(([key, value]: any, i: number) => ({
                        status: 'done',
                        name: key,
                        url: value,
                    }));
                const splitDate = ele.timing.split('to');
                return {
                    ...ele,
                    images: { fileList: imagesList },
                    timing: [moment(splitDate[0], 'hh:mm A'), moment(splitDate[1], 'hh:mm A')],
                };
            });
            setFormData(dataObj);
            form.setFieldsValue(dataObj);
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    function formatTimeRange(startTime: any, endTime: any) {
        const formattedStartTime = moment(startTime).format('h A');
        const formattedEndTime = moment(endTime).format('h A');
        return `${formattedStartTime} to ${formattedEndTime}`;
    }

    function convertSpacesToPercent20(fileName: string) {
        return fileName.replace(/ /g, '%20');
    }

    const imageName = ['desktopPngUrl', 'desktopWebPUrl', 'mobilePngUrl', 'mobileWebPUrl'];

    const handleImageChange = (index: number, fileList: any) => {
        const images: ImageData[] = fileList.map((file: any, idx: number) => {
            let url = file?.response?.data || file.url;
            if (!url) {
                url =
                    'https://amardeep-designs.s3.ap-south-1.amazonaws.com/static-images/' +
                    convertSpacesToPercent20(file.name);
            }
            return {
                url,
            };
        });

        setFormData((prevData: any) => {
            const updatedShowroomDetails = [...prevData.showroomDetails];
            updatedShowroomDetails[index].images = { fileList: images };
            return {
                ...prevData,
                showroomDetails: updatedShowroomDetails,
            };
        });
    };

    const handleFormSubmit = async (values: any) => {
        const payload = {
            ...values,
            showroomDetails: values.showroomDetails.map((ele: any, i: number) => {
                const dateArray = JSON.parse(JSON.stringify(ele.timing));
                const startTime = moment(dateArray[0]);
                const endTime = moment(dateArray[1]);

                const time = formatTimeRange(startTime, endTime);
                const images = ele.images['fileList'];
                const imageObj = images.reduce((acc: any, cur: any, idx: number) => {
                    if (cur.url) {
                        acc[imageName[idx]] = cur.url;
                    } else {
                        acc[imageName[idx]] =
                            cur?.response?.data ||
                            'https://amardeep-designs.s3.ap-south-1.amazonaws.com/static-images/' +
                                convertSpacesToPercent20(cur.name);
                    }
                    return { ...acc };
                }, {});
                return {
                    ...ele,
                    timing: time,
                    images: [{ ...imageObj }],
                };
            }),
        };

        let method: 'POST' | 'PATCH' = 'POST';
        if (id) {
            method = 'PATCH';
        }

        await callApi(CONTACT, method, payload);
        id && closeTab && closeTab(id, true);
    };

    const handleAddShowroom = () => {
        setFormData((prevData: any) => ({
            ...prevData,
            showroomDetails: [
                ...prevData.showroomDetails,
                { location: '', address: '', email: '', mobile: '', timing: '', images: [] },
            ],
        }));
    };

    useEffect(() => {
        if (id) {
            getContactData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='showroomContainer'>
            <h1>Showroom Details</h1>
            <Form
                form={form}
                onFinish={handleFormSubmit}
                initialValues={formData}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}>
                <Form.Item
                    label='Head Office Address'
                    name='headOfficeAddress'
                    rules={[
                        {
                            required: true,
                            message: 'Please enter the Head office address.',
                        },
                    ]}>
                    <Input className='inputField' />
                </Form.Item>

                <Form.Item
                    label='Factory Address'
                    name='factoryAddress'
                    rules={[
                        {
                            required: true,
                            message: 'Please enter the factory address.',
                        },
                    ]}>
                    <Input className='inputField' />
                </Form.Item>

                <Form.Item
                    label='Enquiries Email'
                    name={['enquries', 'email']}
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: 'Please enter a valid Email Id.',
                        },
                    ]}>
                    <Input type='email' />
                </Form.Item>
                <Form.Item
                    label='Enquiries Mobile'
                    name={['enquries', 'mobile']}
                    rules={[{ pattern: REGEX_PHONE_NUMBER, message: customErrorMsg('phone number'), required: true }]}>
                    <Input type='tel' />
                </Form.Item>

                <Form.Item
                    label='Customer Care Email'
                    name={['customerCare', 'email']}
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: 'Please enter a valid Email Id.',
                        },
                    ]}>
                    <Input type='email' />
                </Form.Item>
                <Form.Item
                    label='Customer Care Mobile'
                    name={['customerCare', 'mobile']}
                    rules={[{ pattern: REGEX_PHONE_NUMBER, message: customErrorMsg('phone number'), required: true }]}>
                    <Input type='tel' />
                </Form.Item>

                {formData?.showroomDetails?.map((detail: any, index: number) => (
                    <div key={index}>
                        <h2>Showroom {index + 1}</h2>
                        <Form.Item
                            label='Location'
                            name={['showroomDetails', index, 'location']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the location',
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label='Address'
                            name={['showroomDetails', index, 'address']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the address.',
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label='Email'
                            name={['showroomDetails', index, 'email']}
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: 'Please enter a valid Email Id.',
                                },
                            ]}>
                            <Input type='email' />
                        </Form.Item>
                        <Form.Item
                            label='Mobile'
                            name={['showroomDetails', index, 'mobile']}
                            rules={[
                                {
                                    pattern: REGEX_PHONE_NUMBER,
                                    message: customErrorMsg('phone number'),
                                    required: true,
                                },
                            ]}>
                            <Input type='tel' />
                        </Form.Item>
                        <Form.Item
                            label='Timing'
                            name={['showroomDetails', index, 'timing']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the timing.',
                                },
                            ]}>
                            <TimePicker.RangePicker
                                format='h A'
                                use12Hours
                                defaultValue={[moment('09:00 AM', 'hh:mm A'), moment('07:00 PM', 'hh:mm A')]}
                            />
                        </Form.Item>

                        <Form.Item
                            label='Upload Images'
                            name={['showroomDetails', index, 'images']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please upload the Image.',
                                },
                            ]}>
                            <Upload
                                name={`displayImage`}
                                listType='picture-card'
                                action={`${BASE_API_URL}homepage/upload`}
                                multiple
                                fileList={detail['images']['fileList']}
                                onChange={({ fileList }) => handleImageChange(index, fileList)}>
                                {Array.isArray(detail.images)
                                    ? detail.images.length < 4 && `+ Upload media`
                                    : detail.images.fileList.length < 4 && `+ Upload media`}
                            </Upload>
                        </Form.Item>
                    </div>
                ))}

                <Form.Item>
                    <Button
                        type='dashed'
                        onClick={handleAddShowroom}
                        style={{
                            background: '#586f80',
                            borderRadius: '4px',
                            height: '28px',
                            color: 'white',
                            fontWeight: 600,
                            border: 'none',
                        }}>
                        Add Showroom
                    </Button>
                </Form.Item>

                <Form.Item>
                    <Button
                        type='primary'
                        htmlType='submit'
                        style={{
                            background: '#586f80',
                            borderRadius: '4px',
                            height: '28px',
                            color: 'white',
                            fontWeight: 600,
                            border: 'none',
                        }}>
                        {id ? 'Update' : 'Create'}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Showroom;
