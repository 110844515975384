import { Button, Form, Select, Space, Table, TableProps } from 'antd';
import { useEffect, useRef, useState } from 'react';

import callApi from '../../Api/config';
import { FAQS, FAQS_BY_SECTION } from '../../constants/EntityName';
import DeleteConfirmModal from '../../components/DeleteConfirmModal';
import FaqInputModal from '../../components/FaqInputModal';
import FaqSectionModal from '../../components/FaqSectionModal';

import './style.scss';

const { Option } = Select;

interface DataType {
    key: string;
    question: string;
    answer: string;
    _id: string;
}

const Faqs = () => {
    const [sectionDropData, setSectionDropData] = useState<any>([]);
    const [faqDataBySection, setFaqDataBySection] = useState<any>([]);
    const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
    const [isDeleteSectionModal, setIsDeleteSectionModal] = useState<boolean>(false);
    const [isUpdateModal, setIsUpdateModal] = useState<boolean>(false);
    const [isCreateModal, setIsCreateModal] = useState<boolean>(false);
    const [isCreateSectionModal, setIsCreateSectionModal] = useState<boolean>(false);
    const [isUpdateSectionModal, setIsUpdateSectionModal] = useState<boolean>(false);
    const [section, setSection] = useState<any>('');
    const [selectedFaq, setSelectedFaq] = useState<DataType | null>(null);
    const [selectedSectionId, setSelectedSectionId] = useState<string>('');
    const [sectionSelectedValue, setSectionSelectedValue] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const selectRef = useRef(null);

    const sectionData = async () => {
        const res = await callApi(FAQS, 'GET');
        setSectionDropData(res?.data?.data?.results);
    };

    const getSelectedSectionValue = (id: string) => sectionDropData.filter((ele: any) => ele._id === id)[0];

    const faqBySection = async (sectionId: string) => {
        const res = await callApi(`${FAQS_BY_SECTION}/${sectionId}`, 'GET');
        setSelectedSectionId(sectionId);
        setSectionSelectedValue(getSelectedSectionValue(sectionId));
        setFaqDataBySection(res?.data?.data?.faqs);
    };

    const onDeleteClose = () => {
        setIsDeleteModal(false);
        setIsDeleteSectionModal(false);
    };

    const onUpdateClose = () => {
        setIsUpdateModal(false);
    };
    const onCreateClose = () => {
        setIsCreateModal(false);
    };

    const deleteFaq = async () => {
        await callApi(`${FAQS}/${selectedFaq?._id}`, 'DELETE');
        faqBySection(selectedSectionId);
        setIsDeleteModal(false);
    };

    const handleSubmit = async (url?: string, method?: any) => {
        if (!section && url && method) {
            const payload = {
                question: selectedFaq?.question,
                answer: selectedFaq?.answer,
            };
            await callApi(url, method, payload);
            faqBySection(selectedSectionId);
            setIsUpdateModal(false);
            setIsCreateModal(false);
        } else {
            if (!selectedSectionId) {
                const payload = {
                    sectionName: section?.sectionName,
                    faqs: [{ question: selectedFaq?.question, answer: selectedFaq?.answer }],
                };
                await callApi(FAQS, 'POST', payload);
                sectionData();
                setIsCreateModal(false);
                setSection('');
            } else {
                await callApi(FAQS_BY_SECTION + '/' + selectedSectionId, 'PATCH', section);
                sectionData();
                setIsUpdateSectionModal(false);
                setSection('');
                setSelectedSectionId('');
                setSectionSelectedValue({});
                setIsCreateModal(false);
            }
        }
    };

    const loadMoreOptions = async () => {
        if (loading || !hasMore) return;

        setLoading(true);
        try {
            const res = await callApi(`${FAQS}?page=${page + 1}&limit=10`, 'GET');
            const newData = res?.data?.data?.results;

            if (newData.length > 0) {
                setSectionDropData((prevData: any) => [...prevData, ...newData]);
                setPage((prevPage) => prevPage + 1);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error loading more options:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePopupScroll = (e: any) => {
        const selectDropdown = selectRef.current;
        if (selectDropdown && e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
            loadMoreOptions();
        }
    };

    useEffect(() => {
        sectionData();
    }, []);

    const handleUpdateClick = (record: DataType) => {
        setSelectedFaq(record);
        setIsUpdateModal(true);
    };

    const handleDeleteClick = (record: DataType) => {
        setSelectedFaq(record);
        setIsDeleteModal(true);
    };

    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'question',
        },
        {
            title: 'Answer',
            dataIndex: 'answer',
            key: 'answer',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size='middle'>
                    <button id='faqBtn' onClick={() => handleUpdateClick(record)}>
                        Update
                    </button>
                    <button id='faqDeleteBtn' onClick={() => handleDeleteClick(record)}>
                        Delete
                    </button>
                </Space>
            ),
        },
    ];

    const data: DataType[] = faqDataBySection.map((item: any) => ({
        key: item._id,
        question: item.question,
        answer: item.answer,
        _id: item._id,
    }));

    const createNewFaq = () => {
        setIsCreateModal(true);
        setSelectedFaq(null);
    };

    const createNewSection = () => {
        setIsCreateSectionModal(true);
    };
    const onCloseNewSection = () => {
        setIsCreateSectionModal(false);
    };

    const onNext = () => {
        setIsCreateSectionModal(false);
        setIsCreateModal(true);
        setSelectedFaq(null);
    };

    const updateSection = () => {
        setIsUpdateSectionModal(true);
        const selectedSection = sectionDropData.filter((ele: any) => ele._id === selectedSectionId)[0];
        setSection({ sectionName: selectedSection.sectionName });
    };

    const onCloseUpdateSection = () => {
        setIsUpdateSectionModal(false);
    };

    const deleteSection = () => {
        setIsDeleteSectionModal(true);
    };

    const ondDeleteSection = async () => {
        await callApi(`${FAQS_BY_SECTION}/${selectedSectionId}`, 'DELETE');
        sectionData();
        setSelectedSectionId('');
        setIsDeleteSectionModal(false);
        setSectionSelectedValue({});
    };

    return (
        <div className='formContainer'>
            <div className='faqHeader'>
                <h1>FAQ Details</h1>
            </div>
            <Form>
                <Form.Item label='Section Name' name='Section' rules={[{ required: true, message: 'Please input!' }]}>
                    <div className='formFields'>
                        <Select
                            ref={selectRef}
                            value={sectionSelectedValue?.sectionName}
                            onSelect={(selectedItemId) => faqBySection(selectedItemId)}
                            onPopupScroll={handlePopupScroll}>
                            {sectionDropData?.map((item: any) => (
                                <Option key={item._id} value={item._id}>
                                    {item.sectionName}
                                </Option>
                            ))}
                            {loading && hasMore && (
                                <Option disabled key='loading' value='loading'>
                                    Loading...
                                </Option>
                            )}
                        </Select>
                        <br />
                        <div className='sectionBtn'>
                            <Button key='create' onClick={createNewSection} disabled={selectedSectionId !== ''}>
                                ADD New Section
                            </Button>
                            <Button key='create' onClick={updateSection} disabled={selectedSectionId === ''}>
                                Update Section
                            </Button>
                            <Button
                                className='deleteBtn'
                                key='create'
                                onClick={deleteSection}
                                disabled={selectedSectionId === ''}>
                                Delete Section
                            </Button>
                        </div>
                    </div>
                </Form.Item>
            </Form>
            {selectedSectionId && (
                <>
                    <Table columns={columns} dataSource={data} />
                    <Button key='create' onClick={createNewFaq} id='faqBtn'>
                        Create new FAQ
                    </Button>
                </>
            )}
            {isDeleteModal && <DeleteConfirmModal onDeleteClose={onDeleteClose} onDelete={deleteFaq} />}
            {isDeleteSectionModal && <DeleteConfirmModal onDeleteClose={onDeleteClose} onDelete={ondDeleteSection} />}
            {isUpdateModal && (
                <FaqInputModal
                    close={onUpdateClose}
                    data={selectedFaq}
                    setData={setSelectedFaq}
                    label1={'Question'}
                    label2={'Answer'}
                    handleSubmit={() => handleSubmit(`${FAQS}/${selectedFaq?._id}`, 'PATCH')}
                />
            )}
            {isCreateModal && (
                <FaqInputModal
                    close={onCreateClose}
                    data={selectedFaq}
                    setData={setSelectedFaq}
                    label1={'Question'}
                    label2={'Answer'}
                    handleSubmit={() => handleSubmit(`${FAQS_BY_SECTION}/${selectedSectionId}`, 'POST')}
                />
            )}
            {isCreateSectionModal && (
                <FaqSectionModal
                    close={onCloseNewSection}
                    data={section}
                    setData={setSection}
                    handleSubmit={onNext}
                    label={'Section Name'}
                />
            )}
            {isUpdateSectionModal && (
                <FaqSectionModal
                    close={onCloseUpdateSection}
                    data={section}
                    setData={setSection}
                    handleSubmit={handleSubmit}
                    buttonLabel={'Submit'}
                    label={'Section Name'}
                />
            )}
        </div>
    );
};

export default Faqs;
