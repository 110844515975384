import { errorMsg } from '../constants/messages';

const JobPostingFormData = [
    {
        title: 'Job Posting Details',
        name: 'title',
        label: 'Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Title', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'type',
        label: 'Type',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Type', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'location',
        label: 'Location',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Location', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'multipleTextInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'responsibilities',
        label: 'Responsibilities',
        fieldType: {
            name: 'multipleTextInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Responsibilities' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'requirements',
        label: 'Requirements',
        fieldType: {
            name: 'multipleTextInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Requirements' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'benefits',
        label: 'Benefits',
        fieldType: {
            name: 'multipleTextInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Benefits' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { JobPostingFormData };
